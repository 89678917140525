import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import { Container, Row } from 'reactstrap'
import Img from 'gatsby-image'

import * as Routes from 'routes'
import Layout from 'layouts/static'

import Main from 'components/Main'
import * as CompanyMark from 'components/CompanyMark'
import ButtonLink from 'components/Button/Link'
import Heading from 'components/Heading'
import H2 from 'components/H2'
import H3 from 'components/H3'
import H4 from 'components/H4'
import * as Card from 'components/Card'
import CardLink from 'components/Card/Link'
import Services from 'components/Services'
import Benefits, { List as BenefitsList } from 'components/Benefits'
import * as Benefit from 'components/Benefits/Benefit'
import Portfolio, {
  Mockup as PortfolioMockup
} from 'components/PortfolioWidget'
import Steps from 'components/Steps'
import ContactWidget from 'components/ContactWidget'

const MobileAppsPage = ({ data }) => (
  <Layout header={header}>
    <Helmet title="Aplikacje mobilne">
      <meta
        name="description"
        content="Wykonujemy różnorodne aplikacje mobilne Android i iOS. Szukasz firmy, która rozumie Twój biznes, zjawiskowo projektuje, programuje i wdraża? Trafiłeś na najodpowiedniejsze miejsce."
      />
    </Helmet>

    <Main>
      <Heading id="dlaczego-my">
        <H4>
          <CompanyMark.Left /> Co wyróżnia nasze aplikacje mobilne?{' '}
          <CompanyMark.Right />
        </H4>
        <H2 className="text-xl-left">Dlaczego My, do Twojego projektu?</H2>
      </Heading>

      <Services>
        <Container>
          <Row>
            <div className="col-12 col-md px-4 mb-4 mb-lg-0">
              <Link to={Routes.CONTACT}>
                <Card.Wrapper noIcon>
                  <Card.Icon className="mx-auto">
                    <H3>Szybka chmura</H3>
                  </Card.Icon>
                  <div className="mt-4">
                    Aby całość działała stabilnie, Serce i Rozum aplikacji musi
                    być doskonale zorganizowany. Dlatego umieszczamy go w
                    skalowalnej, wydajnej chmurze. Nasze aplikacje działają bez
                    najmniejszej przerwy na każdym urządzeniu – nawet przy
                    wysokim obciążeniu.
                  </div>
                  <CardLink>
                    Dowiedz się więcej <span>&gt;</span>
                  </CardLink>
                </Card.Wrapper>
              </Link>
            </div>
            <div className="col-12 col-md px-4 mb-4 mb-lg-0">
              <Link to={Routes.CONTACT}>
                <Card.Wrapper noIcon>
                  <Card.Icon className="mx-auto">
                    <H3>Lekka fasada</H3>
                  </Card.Icon>
                  <div className="mt-4">
                    Warstwa graficzna aplikacji, która nie obciąża zasobów, a
                    jednocześnie nie jest uproszczona do maksimum? To nasze
                    zadanie. Projektujemy rzeczy bezkompromisowo - jednocześnie
                    estetycznie i wydajnie.
                  </div>
                  <CardLink>
                    Dowiedz się więcej <span>&gt;</span>
                  </CardLink>
                </Card.Wrapper>
              </Link>
            </div>
            <div className="col-12 col-md px-4">
              <Link to={Routes.CONTACT}>
                <Card.Wrapper noIcon>
                  <Card.Icon className="mx-auto">
                    <H3>Z doskonałym UI/UX</H3>
                  </Card.Icon>
                  <div className="mt-4">
                    Doświadczenie aplikacji ma największy wpływ na ocenę
                    użytkownika - błyskawiczne przełączanie między funkcjami,
                    czytelność modułów, wygodne operowanie interfejsem i
                    funkcjonalność. Tworzymy wszystko, czego potrzebujesz w
                    swojej aplikacji.
                  </div>
                  <CardLink>
                    Dowiedz się więcej <span>&gt;</span>
                  </CardLink>
                </Card.Wrapper>
              </Link>
            </div>
          </Row>
        </Container>
      </Services>

      <Benefits>
        <Heading secondary noBackground>
          <H4>
            <CompanyMark.Left /> Wykonamy wszystko <CompanyMark.Right />
          </H4>
          <H2 className="text-xl-left">Co nie ma dla nas tajemnic?</H2>
          <Container>
            <BenefitsList className="mt-5">
              <Benefit.Wrapper>
                <Benefit.Title>Rejestracja użytkownika</Benefit.Title>
                Logowanie, rejestracje i weryfikacje użytkowników korzystających
                z aplikacji to juz standard. Dlatego zlecając stworzenie nam
                swojej możesz liczyć na klasę.
              </Benefit.Wrapper>
              <Benefit.Wrapper>
                <Benefit.Title>Płatności online</Benefit.Title>
                Zakupy online, zamawianie produktów, dokładne śledzenie statusów
                zamówienia przez aplikacje? E-commerce jest nam bliski, w końcu
                tworzymy również sklepy internetowe.
              </Benefit.Wrapper>
              <Benefit.Wrapper>
                <Benefit.Title>Wykorzystanie sensorów urządzeń</Benefit.Title>
                Geolokalizacja, kamera, akcelerometr? Nasza aplikacja sprytnie
                połączy się z programami na telefonie użytkownika i wykorzysta
                je do Twojego celu. Prosto i z wdziękiem.
              </Benefit.Wrapper>
              <Benefit.Wrapper>
                <Benefit.Title>Bezpieczeństwo</Benefit.Title>
                Bezpieczeństwo w internecie to nasz priorytet. Dlatego
                korzystanie z Twojej aplikacji będzie bezpieczne dzięki
                umiejętnemu szyfrowaniu danych i połączeń
              </Benefit.Wrapper>
              <Benefit.Wrapper>
                <Benefit.Title>Integracje</Benefit.Title>
                Bez problemu zintegrujemy Twoją aplikację z zewnętrznymi
                systemami firmy, takimi jak programy sprzedażowe, CRMy i innymi,
                które nie udostępniają oficjalnej synchronizacji. Będzie
                dokładnie tak, jak tego pragniesz.
              </Benefit.Wrapper>
            </BenefitsList>
          </Container>
        </Heading>
      </Benefits>

      <Portfolio>
        <Container>
          <Row>
            <div className="col-12 col-xl align-self-end mb-5 mb-xl-0">
              <PortfolioMockup>
                <Img
                  fluid={data.portfolioMockup.childImageSharp.fluid}
                  alt="Realizacje"
                />
              </PortfolioMockup>
            </div>
            <div className="col-12 col-xl-7 offset-xl-1">
              <Heading left noBackground>
                <H4 className="text-center text-xl-left">
                  <CompanyMark.Left /> Realizacje <CompanyMark.Right />
                </H4>
                <H2 className="text-center text-xl-left">
                  Zobacz nasze mobilne
                  <br />
                  portfolio<span>.</span>
                </H2>
                <div className="mt-3 text-center text-xl-left">
                  <strong>Jakie projekty już za nami?</strong>
                  <br />
                  <ButtonLink
                    to={Routes.PORTFOLIO}
                    variant="primary"
                    className="mt-4 mb-5"
                  >
                    Zobacz portfolio
                  </ButtonLink>
                </div>
              </Heading>
            </div>
          </Row>
        </Container>
      </Portfolio>

      <Steps secondary>
        <Container>
          <Row>
            <Heading noBackground light>
              <H4>
                <CompanyMark.Left /> Współpraca z nami <CompanyMark.Right />
              </H4>
              <H2>Jak wygląda praca projektowa?</H2>
            </Heading>
          </Row>
          <Row>
            <div className="col-12 col-md-11 mx-auto mt-5 pt-3">
              Po zebraniu listy Twoich wymagań i doprecyzowaniu szczegółów,
              tworzymy dwa projekty - funkcjonalny i graficzny. Dopiero po ich
              akceptacji przechodzimy do realizacji projektu. Następnie
              testujemy produkt na wielu urządzeniach. Na samym końcu
              umieszczamy Twoją aplikację w Sklepie Play i App Store.
            </div>
          </Row>
        </Container>
      </Steps>

      <ContactWidget
        title="Wypełnij krótki formularz"
        subtitle="Odezwiemy się do Ciebie tak szybko, jak to możliwe"
      />
    </Main>
  </Layout>
)

export const query = graphql`
  query {
    portfolioMockup: file(
      relativePath: { eq: "page_mobile_apps/portfolio_mockup.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 355, maxHeight: 454) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const header = {
  title: (
    <Fragment>
      Aplikacje mobilne dla każdego biznesu<span>.</span>
    </Fragment>
  ),
  subtitle: <Fragment>Aplikacje</Fragment>,
  content: (
    <div>
      <div className="my-5">
        Jesteśmy zespołem, który wykonuje również różnorodne
        <br />
        aplikacje mobilne Android i iOS. Szukasz firmy,
        <br />
        która rozumie Twój biznes, zjawiskowo projektuje,
        <br />
        programuje i wdraża?
        <br />
        Trafiłeś na najodpowiedniejsze miejsce.
      </div>
      <ButtonLink
        to="#"
        variant="primary"
        onClick={e => {
          e.preventDefault()

          document
            .getElementById('dlaczego-my')
            .scrollIntoView({ behavior: 'smooth' })
        }}
      >
        Dowiedz się więcej
      </ButtonLink>
    </div>
  ),
  image: 'mobileApps'
}

export default MobileAppsPage
